import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import Posts from "../../components/blog/Posts/Posts";
import { CenteredContentWrapper } from "@xolvio/xolvio-ui";
import styled from "styled-components";
import { spacing } from "@xolvio/xolvio-ui";
import { ConfettiHeader } from "../../components/ConfettiHeader";
import { pageData } from "../../pages-content/articles";

export default () => (
  <LayoutDefault>
    <ConfettiHeader {...pageData.header} />
    <PostsWrapper>
      <Posts />
    </PostsWrapper>
  </LayoutDefault>
);

export const PostsWrapper = styled(CenteredContentWrapper)`
  margin: ${2 * spacing.mobile.padding.default}px
    ${spacing.mobile.padding.default}px 80px;
`;
