import React from "react";
import { PostTile } from "../Posts/PostTile";
import { BlogLayout } from "../Posts/Posts";
import { getPostUrl } from "../../../../getPageUrl";
import { ConfettiHeader } from "../../ConfettiHeader";
import { LayoutDefault } from "../../LayoutDefault";
import { PostsWrapper } from "../../../pages/blog/articles";

export const PostsPerTagPageTemplate = ({ pageContext }) => {
  const { posts = [] } = pageContext;

  return (
    <LayoutDefault>
      <ConfettiHeader
        text={`Posts tagged ${pageContext.tag}`}
        highlights={pageContext.tag}
      />
      <PostsWrapper>
        <BlogLayout className={`modulo-${posts.length % 3}`}>
          {posts.map(post => {
            return (
              <PostTile
                {...post.node}
                url={getPostUrl(post.node)}
                key={post.node.slug}
              />
            );
          })}
        </BlogLayout>
      </PostsWrapper>
    </LayoutDefault>
  );
};

export default PostsPerTagPageTemplate;
